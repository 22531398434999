<script lang="ts">
	import type { Image } from "../../../../core/schema/Image.js";
	import { parseSourceSet } from "./parseSourceSet.js";
	import type { SourceSetQuery } from "./SourceSetQuery.js";
	import { thumb } from "../../../../core/utils/thumb.js";
	import { onMount } from "svelte";
	import { sortSourceSetQuery } from "./sortSourceSetQuery.js";

	export let width: number;
	export let height: number;
	export let imgClass = "";
	export let loading: "eager" | "lazy";
	export let image: Image;
	export let alt: string;
	export let sourcesets: Partial<Record<SourceSetQuery, [number, number]>> = {};
	export let bucketUrl: string;
	export let naturalHeight: number | undefined = undefined;
	export let naturalWidth: number | undefined = undefined;

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, [number, number]][];

	onMount(() => {
		window.refreshFsLightbox?.();
	});
</script>

<picture>
	{#each sortedSourcesets as [query, [width, height]]}
		<source srcset={`${thumb(bucketUrl, width, height, image)} 1x`} type="image/webp" media={parseSourceSet(query)} />
	{/each}

	<img
		class={imgClass}
		{alt}
		src={thumb(bucketUrl, width, 0, image)}
		{width}
		{height}
		{loading}
		bind:naturalHeight
		bind:naturalWidth
	/>
</picture>
